import { Book, Message } from "@hi18n/core";
import catalogScanco from "./scanco";
import coreChainCatalog from "./corechain";
import { BatchId } from "@corechain-technologies/types";

export type Vocabulary = {
    // "<Translation ID>": Message<{ <Parameters> }>; (or simply Message; if you don't need parameters)
    "app/error/404": Message;
    "app/error/404-title": Message;
    "app/error/404-subtitle": Message;
    "app/error/404-button": Message;
    "app/error/unknown": Message;
    "app/error/unknown-title": Message;
    "app/error/unknown-subtitle": Message;
    "app/error/unknown-button": Message;
    "app/greeting": Message;
    "app/usage": Message;
    "app/terms-of-service": Message;
    "app/privacy-policy": Message;
    "app/title": Message;
    "app/sidebar/logo": Message;
    "app/sidebar/line1": Message;
    "app/sidebar/line2": Message;
    "app/nav/home": Message;
    "app/nav/payments": Message;
    "app/nav/vendors": Message;
    "app/nav/receivables": Message;
    "app/nav/payers": Message;
    "app/nav/reports": Message;
    "app/nav/settings": Message;
    "app/nav/help": Message;
    "app/notifications": Message;
    "app/notifications/duplicateFile": Message;
    "app/notifications/uploadSuccess": Message;
    "app/notifications/uploadError": Message<{ namespace: string }>;
    "app/notifications/uploadFetchError": Message<{ batchId: BatchId }>;
    "app/notifications/errorDefault": Message;
    "activityTable/columnHeaders/amount": Message;
    "activityTable/columnHeaders/uploadedFileName": Message;
    "activityTable/columnHeaders/date": Message;
    "activityTable/columnHeaders/createdDate": Message;
    "activityTable/columnHeaders/fileId": Message;
    "activityTable/columnHeaders/originationDate": Message;
    "activityTable/columnHeaders/statusDate": Message;
    "activityTable/columnHeaders/method": Message;
    "activityTable/columnHeaders/payee": Message;
    "activityTable/columnHeaders/payer": Message;
    "activityTable/columnHeaders/paymentIdReceivables": Message;
    "activityTable/columnHeaders/status": Message;
    "activityTable/columnHeaders/transaction": Message;
    "batchDetail/originatingAccount": Message;
    "batchDetail/totalAmount": Message;
    "batchDetail/numberPayments": Message;
    "comparisonCards/vendorsByVolume": Message;
    "comparisonCards/vendorsByPayments": Message;
    "comparisonCards/paymentByMethod": Message;
    "comparisonCards/payersByVolume": Message;
    "comparisonCards/payersByPayments": Message;
    "comparisonCards/volumeReceivedByMethod": Message;
    currency: Message;
    "dummyListing/duplicate": Message;
    "dummyListing/fileUploadError": Message;
    "header/greeting": Message<{ name: string }>;
    "header/verified": Message;
    "header/sr/company": Message;
    "header/sr/accountStatus": Message;
    "insightCards/lifetimeVolumePaid": Message;
    "insightCards/lifetimeTotalPaid": Message;
    "insightCards/notSettled": Message;
    "insightCards/lifetimeVolumeReceived": Message;
    "insightCards/lifetimeTotalReceived": Message;
    "insightCards/notReceived": Message;
    "payerList/title": Message;
    "payerList/companyName": Message;
    "payerList/lastPayment": Message;
    "payerList/lastPaymentInline": Message;
    "payerList/enrolled": Message;
    "payerList/status": Message;
    "payerList/role": Message;
    "payerList/amountReceivedInline": Message;
    "payerList/totalReceivedInline": Message;
    "payerPaymentHistory/paymentWithTotalValue": Message;
    "payerPaymentHistory/paymentsWithTotalValue": Message;
    "paymentsTimeline/header": Message<{ maxDataRange: string }>;
    "paymentDetail/paymentTo": Message;
    "paymentDetail/originalBatchId": Message;
    "paymentDetail/lifecycleMarkers/flagged": Message;
    "paymentDetail/lifecycleMarkers/rejected": Message;
    "paymentDetail/lifecycleMarkers/failed": Message;
    "paymentDetail/lifecycleMarkers/created": Message;
    "paymentDetail/lifecycleMarkers/approved": Message;
    "paymentDetail/lifecycleMarkers/scheduled": Message;
    "paymentDetail/lifecycleMarkers/pending": Message;
    "paymentDetail/lifecycleMarkers/delivered": Message;
    "paymentDetail/lifecycleMarkers/accepted": Message;
    "paymentDetail/lifecycleMarkers/settled": Message;
    "placeholder/header": Message;
    "placeholder/status": Message;
    "placeholder/currency": Message;
    "placeholder/notApplicable": Message;
    "receivables/title": Message;
    "receivablesDetail/paymentFrom": Message;
    "receivablesDetail/receivingAccount": Message;
    "receivablesDetail/totalAmount": Message;
    "receivablesDetail/paymentId": Message;
    "privacyPolicy/title": Message;
    recentActivity: Message;
    "reports/downloadReport": Message;
    "reports/title": Message;
    "reports/lifetimeStatistics": Message;
    "reports/paymentsByDate": Message;
    "reports/vendorLifetime": Message;
    "search/placeholder": Message;
    "tabs/paymentsIndividual": Message;
    "tabs/paymentSets": Message;
    "termsOfService/title": Message;
    "uploadBox/payment": Message;
    "uploadBox/vendor": Message;
    "vendorList/title": Message;
    "vendorList/companyName": Message;
    "vendorList/contactName": Message;
    "vendorList/lastPaid": Message;
    "vendorList/status": Message;
    "vendorList/role": Message;
    "vendorPaymentHistory/paymentWithTotalValue": Message;
    "vendorPaymentHistory/paymentsWithTotalValue": Message;
};

export type ErrorCode =
    | "unknown"
    | keyof {
          [key in keyof Vocabulary & `app/error/${number}` as key extends `app/error/${infer U}`
              ? U
              : never]: unknown;
      };

export const book = new Book<Vocabulary>({
    scanco: catalogScanco,
    corechain: coreChainCatalog,
});
