import { useState, useEffect } from "react";
import ProfileDropdown from "./components/ProfileDropdown";
import Search from "./components/Search";
import Sidebar from "./components/Sidebar";
import { Bars3CenterLeftIcon, BellIcon } from "@heroicons/react/24/outline";
import { Outlet } from "react-router-dom";
import "./tailwind.scss";
import { useAppDispatch, useAppSelector } from "./hooks";
import { getActiveNotifications } from "./store/notifications";
import Notification from "./components/Notification";
import Feature from "./components/Feature";
import { useI18n } from "@hi18n/react";
import { book } from "./locale";
import { DetailPane } from "./components/DetailPane";
import { AuthStatus, selectAuthStatus } from "./store/user";
import { fetchBatches, fetchFilteredBatches, selectBatchesStatus } from "./store/batches";
import { initStatistics } from "./store/statistics";
import { selectPayer } from "./store/payer";
import { fetchFilteredPayments } from "./store/payments";

const App = () => {
    const { t } = useI18n(book);
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const notifications = useAppSelector(getActiveNotifications);
    const show = notifications.length > 0;
    const authStatus = useAppSelector(selectAuthStatus);
    const dispatch = useAppDispatch();
    const payer = useAppSelector(selectPayer);
    const fetched = useAppSelector(selectBatchesStatus) === "INITIALIZED";

    useEffect(() => {
        if (payer && authStatus === AuthStatus.LOGGED_IN) {
            dispatch(fetchBatches()); // TODO: remove this in favor of batch-packaging the payments on the front end
            dispatch(fetchFilteredPayments({ payerId: payer.id }));
            dispatch(fetchFilteredBatches({ payerId: payer.id }));
            dispatch(initStatistics(payer.id));
        }
    }, [payer, authStatus, dispatch]);

    useEffect(() => {
        document.title = t("app/title");
    }, [t]);

    return (
        <>
            <div className="min-h-full">
                <div className="eleven21:pl-64 flex flex-col flex-1">
                    <div className="flex-shrink-0 flex h-16 bg-white border-b border-gray-200 lg:border-none shadow print:hidden relative">
                        <button
                            type="button"
                            className="px-4 border-r border-gray-200 text-gray-400 eleven21:hidden"
                            onClick={() => setSidebarOpen(true)}
                        >
                            <span className="sr-only">Open sidebar</span>
                            <Bars3CenterLeftIcon className="h-6 w-6" aria-hidden="true" />
                        </button>

                        <div className="flex-1 px-4 flex justify-between sm:px-6 eleven21:max-w-6xl eleven21:mx-auto eleven21:px-8">
                            <Search />
                            <div className="ml-4 flex items-center md:ml-6">
                                <Feature flag="DEPLOY_TARGET" value="DEVELOPMENT">
                                    <button
                                        type="button"
                                        className="bg-white p-1 rounded-full text-gray-400 hover:text-gray-500"
                                    >
                                        <span className="sr-only">{t("app/notifications")}</span>
                                        <BellIcon className="h-6 w-6 text-gray-600" aria-hidden="true" />
                                    </button>
                                </Feature>
                                <ProfileDropdown />
                            </div>
                        </div>
                    </div>
                    <main className="flex-1 pb-8">
                        <DetailPane />
                        <Outlet />
                    </main>
                </div>
                <Sidebar isOpen={sidebarOpen} setOpen={setSidebarOpen} />

                {/* Global notification live region, render this permanently at the end of the document */}
                <div
                    aria-live="assertive"
                    className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6"
                >
                    <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
                        <Notification show={show} data={notifications[0]} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default App;
