import LoadingListing from "./LoadingListing";
import { useAppSelector } from "../hooks";
import { isDuplicate } from "../store/listings";
import Feature from "./Feature";
import { useI18n } from "@hi18n/react";
import { book } from "../locale";
import PaginationControls from "./PaginationControls";
import { Outlet } from "react-router-dom";
import ColumnHeader from "./ColumnHeader";
import { isFileUploadError, isLoadingBatchListings, isUpdatingBatches } from "../store/batches";
import { PrintHeader } from "./PrintHeader";
import { selectHideBatchesTable } from "../store/payer";
import DummyListingForUploadError from "./DummyListingForUploadError";
import DummyListingForDuplicateError from "./DummyListingForDuplicateError";
import { UpdatingPulse } from "./UpdatingPulse";
import PaymentListingDesktop from "./PaymentListingDesktop";
import PaymentListingMobile from "./PaymentListingMobile";
import FilterControls from "./FilterControls";
import { isLoadingPayments, selectPayments } from "../store/payments";
import { downloadFilteredPaymentsReport } from "../utils/reporting";

const PaymentsMobile = () => {
    const payments = useAppSelector(selectPayments);

    return (
        //TODO: Fix me!!!
        <div className="shadow sm:hidden print:hidden">
            <ul className="mt-3 divide-y divide-gray-200 overflow-hidden shadow sm:hidden">
                {payments.map((payment) => (
                    <PaymentListingMobile payment={payment} key={payment.correlationId} />
                ))}
            </ul>
            <Feature flag="DEPLOY_TARGET" value="DEVELOPMENT">
                <PaginationControls />
            </Feature>
        </div>
    );
};

const PaymentsDesktop: React.FC = () => {
    const { t } = useI18n(book);
    const payments = useAppSelector(selectPayments);

    const isLoadingListings = useAppSelector(isLoadingBatchListings) || useAppSelector(isLoadingPayments);
    const isDuplicateListing = useAppSelector(isDuplicate);
    const isFileUploadErrorListing = useAppSelector(isFileUploadError);
    const updating = useAppSelector(isUpdatingBatches);

    const hideBatchesTable = useAppSelector(selectHideBatchesTable);

    return (
        <div className="hidden sm:block print:block">
            <div className="max-w-10xl mx-auto px-4 sm:px-6 lg:px-8">
                <PrintHeader title={t("tabs/paymentsIndividual")} />
                <div className={`${hideBatchesTable ? "mt-8" : "mt-2"}  flex flex-col`}>
                    <div className="inline-block align-middle min-w-full pb-2">
                        <div className="shadow-sm print:shadow-none ring-1 ring-black print:ring-transparent ring-opacity-5 relative">
                            {updating && <UpdatingPulse />}
                            <button
                                type="button"
                                className="absolute -top-12 right-0 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-inset focus:ring-branded-primary hover:bg-gray-50"
                                onClick={() => downloadFilteredPaymentsReport(payments)}
                            >
                                Download Report
                            </button>
                            <table className="min-w-full divide-y divide-gray-200 border-separate border-spacing-0">
                                <thead className="bg-gray-50 card">
                                    <tr>
                                        <ColumnHeader
                                            sortValue="originationDate"
                                            textAlign="left"
                                            className="pl-4"
                                        />
                                        <ColumnHeader sortValue="payee" className="pl-2" />
                                        <ColumnHeader sortValue="amount" textAlign="right" className="" />
                                        {/* method //TODO - unhide */}
                                        {/* <th
                                            className="sticky print:static top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 backdrop-blur backdrop-filter pl-2 py-3 text-left text-sm font-semibold text-gray-800 hidden md:table-cell print:table-cell"
                                            scope="col"
                                        >
                                            <div className="group inline-flex">
                                                {t("activityTable/columnHeaders/method")}
                                                <span className="invisible ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible">
                                                    <div className="h-5 w-5" aria-hidden="true">
                                                 
                                                    </div>
                                                </span>
                                            </div>
                                        </th> */}
                                        <ColumnHeader
                                            sortValue="status"
                                            textAlign="left"
                                            className="hidden pl-2 md:table-cell print:table-cell"
                                        />
                                        <ColumnHeader
                                            sortValue="statusDate"
                                            textAlign="right"
                                            className="pr-4"
                                        />
                                    </tr>
                                </thead>

                                <tbody className="bg-white divide-y divide-gray-200">
                                    <FilterControls view="PAYMENTS" />
                                    {isLoadingListings && <LoadingListing batches={false} />}
                                    {isFileUploadErrorListing && (
                                        <DummyListingForUploadError batches={false} />
                                    )}
                                    {isDuplicateListing && <DummyListingForDuplicateError batches={false} />}
                                    {payments.map((payment) => {
                                        return (
                                            <PaymentListingDesktop
                                                payment={payment}
                                                key={payment.correlationId}
                                            />
                                        );
                                    })}
                                </tbody>
                            </table>
                            {/* Pagination */}
                            <Feature flag="DEPLOY_TARGET" value="DEVELOPMENT">
                                <nav
                                    className="bg-white px-4 py-3 flex items-center justify-between sm:px-6 print:hidden"
                                    aria-label="Pagination"
                                >
                                    <div className="hidden sm:block">
                                        <p className="text-sm text-gray-700">
                                            Showing<span className="font-medium"> 1</span> to
                                            <span className="font-medium"> {payments.length}</span> of
                                            <span className="font-medium"> {payments.length}</span> results
                                        </p>
                                    </div>
                                    <div className="flex-1 flex justify-between sm:justify-end">
                                        <div className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
                                            Previous
                                        </div>
                                        <div className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
                                            Next
                                        </div>
                                    </div>
                                </nav>
                            </Feature>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const PaymentsTable: React.FC = () => {
    return (
        <>
            <Outlet />

            {/* Activity list (smallest breakpoint only */}
            <PaymentsMobile />

            {/* Activity table (small breakpoint and up) */}
            <PaymentsDesktop />
        </>
    );
};

export default PaymentsTable;
